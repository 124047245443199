import Close from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import Bowser from 'bowser';
import { format } from 'date-fns';
import React from 'react';
import { StatementData } from '.';
import { RouteError } from '../../components/route-container/route-error';
import { AuthContext } from '../../contexts/auth-context';

const DocIframe = React.memo(function DocIframe(props: { data: any }) {
  // State
  const [loading, setLoading] = React.useState(true);

  return (
    <React.Fragment>
      {loading ? <LinearProgress /> : null}

      <iframe
        title="Monthly Statement"
        src={window.URL.createObjectURL(
          new Blob([props.data], { type: 'application/pdf' }),
        )}
        style={{ flex: 1, width: '100%', border: 'none' }}
        onLoad={() => setLoading(false)}
      />
    </React.Fragment>
  );
});

const isAndroidDevice = (): boolean => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const osName = browser.getOSName();
  return osName === 'Android';
};

export function StatementPDF(props: {
  item: StatementData | undefined;
  onClose: () => void;
}) {
  // Props
  const { item } = props;
  // Context
  const {
    state: { impersonatedAgent, user },
  } = React.useContext(AuthContext);

  const AgtNo = impersonatedAgent?.AgtNo || user?.getUsername();

  const isAndroid = isAndroidDevice();

  const path = '/leads/monthly-statements/download';
  const MSId = item?.MSId;
  const query = useQuery({
    enabled: Boolean(item !== undefined && item.MSId !== null),
    queryKey: [path, { AgtNo, MSId, isAndroid }],
    queryFn: async () => {
      const response = await API.post('LeadsAPI', path, {
        responseType: 'arraybuffer',
        body: { AgtNo, MSId },
      });

      if (response && isAndroid) {
        const blob = new Blob([response], { type: 'application/text' });
        const linkTrans = document.createElement('a');
        linkTrans.href = URL.createObjectURL(blob);
        linkTrans.setAttribute('download', `${AgtNo}_${item?.ForDate}`);
        document.body.appendChild(linkTrans);
        linkTrans.click();
        document.body.removeChild(linkTrans);
        return response;
      } else if (response) {
        return response;
      }
      return null;
    },
  });

  return (
    <Drawer
      container={window.document.body}
      variant="temporary"
      anchor="right"
      open={props.item !== undefined}
      onClose={props.onClose}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: { xs: '100vw', md: '60vw', lg: '50vw', xl: '40vw' },
          height: '100dvh',
        },
      }}
    >
      <Toolbar>
        <IconButton
          sx={{ mr: 2 }}
          disabled={query.isLoading}
          onClick={props.onClose}
        >
          {query.isLoading ? <CircularProgress size={24} /> : <Close />}
        </IconButton>

        <Stack spacing={1} direction="row" alignItems="center" sx={{ flex: 1 }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              fontFamily: 'Roboto Mono',
            }}
          >
            {item?.ForDate &&
              format(new Date(item?.ForDate + 'T00:00'), 'MMMM yyyy')}
          </Typography>
          <Box flex={1} sx={{ p: 1 }}>
            <Divider />
          </Box>

          <Typography
            variant="h6"
            sx={{ fontWeight: 'bold', fontFamily: 'Roboto Mono' }}
          >
            {AgtNo}
          </Typography>
        </Stack>
      </Toolbar>
      {query.isFetching ? (
        <LinearProgress />
      ) : query.isError ? (
        <RouteError />
      ) : query.data ? (
        <DocIframe data={query.data} />
      ) : null}
    </Drawer>
  );
}
