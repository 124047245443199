import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { format, lastDayOfMonth } from 'date-fns';
import { orderBy } from 'lodash';
import React from 'react';
import { RouteContainer } from '../../components/route-container';
import { AuthContext } from '../../contexts/auth-context';
import { usePermissions } from '../../hooks/use-permissions';
import { AWS_GROUP } from '../../types';
import { isAgt } from '../../utils/is-agent';
import { StatementPDF } from './statement-pdf';

export interface StatementData {
  MSId: number;
  ForDate: string;
  CreatedDate: string;
}

export function MonthlyStatements() {
  // Context
  const {
    state: { impersonatedAgent, user },
  } = React.useContext(AuthContext);
  const AgtNo = impersonatedAgent?.AgtNo || user?.getUsername();
  // State
  const [selected, setSelected] = React.useState<number>();
  // Hooks
  const { hasGroup } = usePermissions();

  // Query - Get Monthly Statements
  const path = '/leads/monthly-statements';
  const queryKey = [path, { AgtNo }];
  const query = useQuery({
    queryKey,
    queryFn: async () => {
      if (isAgt(AgtNo)) {
        const response: {
          data: StatementData[];
        } = await API.post('LeadsAPI', path, { body: { AgtNo } });
        return response.data;
      }
      return [];
    },
  });

  const statements = orderBy(query.data || [], 'ForDate', 'desc');

  return (
    <React.Fragment>
      <RouteContainer routeTitle="Monthly Statements" loading={query.isLoading}>
        <Container maxWidth="sm">
          <Box sx={{ pt: 2, pb: 16 }}>
            <Stack spacing={1}>
              {!isAgt(AgtNo) &&
              hasGroup(AWS_GROUP.LDS_GlobalAdmin) &&
              statements.length === 0 ? (
                <Box
                  sx={{
                    p: 4,
                    textAlign: 'center',
                    backgroundColor: '#f5f5f5',
                    border: '1px solid #cacaca',
                    borderRadius: 2,
                  }}
                >
                  <Box sx={{ fontFamily: 'Roboto Mono' }}>
                    Impersonate an Agent to view their Invoices
                  </Box>
                </Box>
              ) : statements.length === 0 ? (
                <Box
                  sx={{
                    p: 4,
                    textAlign: 'center',
                    backgroundColor: '#f5f5f5',
                    border: '1px solid #cacaca',
                    borderRadius: 2,
                  }}
                >
                  <Box sx={{ fontFamily: 'Roboto Mono' }}>
                    No invoices found
                  </Box>
                </Box>
              ) : null}

              {statements.map((item) => {
                const lastDay = lastDayOfMonth(
                  new Date(item.ForDate + 'T00:00'),
                );
                return (
                  <Box
                    key={item.MSId}
                    component="button"
                    sx={{
                      color: '#000',
                      textAlign: 'left',
                      borderWidth: 2,
                      borderStyle: 'solid',
                      borderColor: '#eaeaea',
                      borderRadius: 1,
                      backgroundColor: '#fff',
                      cursor: 'pointer',
                      transition: 'all 0.2s',
                      ':hover': {
                        transform: 'scale(1.01)',
                        borderColor: '#aeaeae',
                      },
                    }}
                    onClick={() => setSelected(item.MSId)}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      spacing={1}
                      sx={{ py: 1 }}
                    >
                      <Box flex={1} alignItems="center" px={2}>
                        <Divider />
                      </Box>
                      <Box>
                        <Box
                          sx={{
                            fontSize: 22,
                          }}
                        >
                          {format(
                            new Date(item.ForDate + 'T00:00'),
                            'MMMM do, yyyy',
                          )}{' '}
                          thru {format(lastDay, 'MMMM do, yyyy')}
                        </Box>
                        <Box
                          sx={{
                            textAlign: 'center',
                            fontStyle: 'italic',
                            fontFamily: 'Roboto Mono',
                            fontSize: 11,
                          }}
                        >
                          <b>Generated:</b> {item.CreatedDate}
                        </Box>
                      </Box>
                      <Box flex={1} alignItems="center" px={2}>
                        <Divider />
                      </Box>
                    </Stack>
                  </Box>
                );
              })}
            </Stack>
          </Box>
        </Container>
      </RouteContainer>
      <StatementPDF
        item={query.data?.find((i) => i.MSId === selected)}
        onClose={() => setSelected(undefined)}
      />
    </React.Fragment>
  );
}
