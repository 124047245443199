import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useQuery } from '@tanstack/react-query';
import { API, Auth } from 'aws-amplify';
import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/auth-context';
import { AuthorizeData } from '../types';
import { captureError } from '../utils/capture-error';
import { checkForImpersonatedUser } from '../utils/impersonate';
import { checkForRedirectPath, storeRedirectPath } from '../utils/redirect';
import { AuthorizeError } from './authorize-error';
import { AuthorizeForbidden } from './authorize-forbidden';
import { MaintenanceMessage } from './maintenance-message';
import { MFACheck } from './mfa-check';

export function RequireAuth(props: {
  children: JSX.Element;
  isAdmin: boolean;
  maintData:
    | {
        maintenanceActive: boolean;
        maintenanceBegin: string;
        maintenanceEnd: string;
      }
    | null
    | undefined;
}) {
  // Context
  const { state, dispatch } = React.useContext(AuthContext);
  // Hooks
  const navigate = useNavigate();

  React.useEffect(() => {
    const handleCheckForPath = async () => {
      try {
        const redirectPath = await checkForRedirectPath();
        if (redirectPath) {
          navigate(redirectPath);
        }
      } catch (error) {
        captureError({ data: { error } });
      }
    };

    const handleCheckForImpersonatedUser = async () => {
      try {
        const impersonatedUser = await checkForImpersonatedUser();
        if (impersonatedUser) {
          dispatch({ type: 'IMPERSONATE_AGENT', payload: impersonatedUser });
        }
      } catch (error) {
        captureError({ data: { error } });
      }
    };

    const handleStorePath = async () => {
      try {
        if (window?.location?.pathname) {
          const { pathname } = window.location;
          // If the user was trying to access a route other than the main route
          if (pathname !== '/') {
            let path = pathname;
            // If there are any search parameters add them to the redirect path
            if (window?.location?.search) {
              path = path + window.location.search;
            }
            // Add the route to storage for use after logging in
            await storeRedirectPath({ pathname: path });
          }
        }
      } catch (error) {
        captureError({ data: { error } });
      }
    };

    if (state.user !== undefined) {
      // Check for stored path
      handleCheckForPath();
      // Check for stored impersonated user
      handleCheckForImpersonatedUser();
    } else {
      // Store path
      handleStorePath();
    }
  }, [state.user, navigate, dispatch]);

  const isAuthenticated = state.user !== undefined;

  // Query - Authorize User
  const pathAuth = '/authorize';
  const queryAuth = useQuery({
    enabled: isAuthenticated,
    queryKey: [pathAuth],
    queryFn: async () => {
      const response: {
        data: AuthorizeData;
      } = await API.post('LeadsAPI', pathAuth, {});

      if (response.data) {
        dispatch({ type: 'SET_AUTHORIZE', payload: response.data });
      }

      return response.data;
    },
  });

  // Query - Check that the user has set up Multi-Factor Authentication for their account
  const queryMFA = useQuery({
    enabled: isAuthenticated,
    queryKey: ['check_Auth.getPreferredMFA'],
    queryFn: async () => {
      // Bypass Cache to always get the latest preferences for the Current User
      const currentUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      const preferredMFA = await Auth.getPreferredMFA(currentUser);

      const mfaOptions = ['EMAIL_OTP', 'SMS_MFA', 'SOFTWARE_TOKEN_MFA'];

      // Determine if the user has selected one of the available options
      return mfaOptions.includes(preferredMFA || '');
    },
  });

  const isLoadingMFA = queryMFA.isLoading && queryMFA.fetchStatus !== 'idle';

  let isForbidden = false;
  if (queryAuth.isError && queryAuth.error instanceof Error) {
    isForbidden = queryAuth.error.message.includes('403');
  }

  if (!props.isAdmin && props.maintData && props.maintData.maintenanceActive) {
    // Display a Maintenance Message to agents
    return <MaintenanceMessage data={props.maintData} />;
  } else if (state.isChecking || queryAuth.isFetching || isLoadingMFA) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    );
  } else if (!queryMFA.data && isAuthenticated) {
    return <MFACheck />;
  } else if (isForbidden) {
    return <AuthorizeForbidden />;
  } else if (queryAuth.isError) {
    return <AuthorizeError />;
  } else if (isAuthenticated) {
    return props.children;
  } else {
    return <Navigate to="/sign-in" replace />;
  }
}
